@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.sidebar{
    position: fixed;
    left: 0;
    top: 0;
    background: var(--primary-blue);
    height: 100%;
    width: 260px;
    z-index: 100;
    transition: all 0.5s ease;
}

.sidebar.close{
    width: 78px;
}

.sidebar .logo-details{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
}



.sidebar .logo-details i{
    font-size: 30px;
    color: #fff;
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
}

.sidebar .logo-details .logo_name{
    font-size: 22px;
    color: #fff;
    font-weight: 600;
    transition: 0.3s ease;
    transition-delay: 0.1s;
}

.sidebar.close .logo-details .logo_name{
    transition-delay: 0s;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links{
    height: 100%;
    padding: 30px 0 150px 0;
    overflow: auto;
}

.sidebar.close .nav-links{
    overflow: visible;
}

.sidebar .nav-links::-webkit-scrollbar{
    display: none;
}

.sidebar .nav-links li{
    position: relative;
    list-style: none;
    transition: all 0.4s ease;
}

.sidebar .nav-links li:hover{
    background: var(--primary-blue-oscure);
}

.sidebar .nav-links li .iocn-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar.close .nav-links li .iocn-link{
    display: block;
}

.sidebar .nav-links li i{
    height: 50px;
    min-width: 78px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar .nav-links li.showMenu i.arrow{
    transform: rotate(-180deg);   
}

.sidebar.close .nav-links i.arrow{
    display: none;
}

.sidebar .nav-links li a{
    text-decoration: none;
    display: flex;
    align-items: center;
}


.sidebar .nav-links li a .link_name{
    font-size: 18px;
    font-weight: 400;
    color: #fff;
}

.sidebar.close .nav-links li a .link_name{
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li .sub-menu{
    padding: 6px 6px 14px 80px;
    margin-top: -10px;
    background: var(--primary-blue-oscure);
    display: none;
}

.sidebar .nav-links li.showMenu .sub-menu{
    display: block;
}

.sidebar .nav-links li .sub-menu a{
    color: #fff;
    font-size: 15px;
    padding: 5px 0px;
    white-space: nowrap;
    opacity: 0.6;
    transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu a:hover{
    opacity: 1;
}

.sidebar.close .nav-links li .sub-menu{
    position: absolute;
    left: 100%;
    top: -10px;
    margin-top: 0;
    padding: 10px 20px;
    border-radius: 0 6px 6px 0;
    opacity: 0;
    display: block;
    pointer-events: none;
    transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu{
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name{
    display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name{
    display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name{
    font-size: 18px;
    opacity: 1;
    display: block;
}

.sidebar .nav-links li .sub-menu.blank{
    opacity: 1;
    pointer-events: auto;
    padding: 3px 20px 6px 16px;
    opacity: 0;
    pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank{
    top: 50%;
    transform: translateY(-50%);
}

.sidebar .profile-details{
    position: fixed;
    bottom: 0;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-blue-oscure);
    padding: 6px 0;
    transition: all 0.5s ease;
}

.sidebar.close .profile-details{
    background: none;
    width: 78px;
}

.sidebar .profile-details .profile-content{
    display: flex;
    align-items: center;
}


.sidebar .profile-details img{
    height: 52px;
    width: 52px;
    object-fit: cover;
    border-radius: 16px;
    margin: 0 14px 12px;
    background: var(--primary-blue-oscure);
    transition: all 0.5s ease;
}

.sidebar.close .profile-details img{
    padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job{
    display: none;
}

.sidebar .profile-details .job{
    font-size: 12px;
}

.home-section{
    position: relative;
    background: #efefef;
    height: 100vh;
    left: 260px;
    width: calc(100% - 260px);
    transition: all 0.5s ease;
}

.sidebar.close ~ .home-section{
    left: 78px;
    width: calc(100% - 78px);
}

.home-section .home-content{
    height: 60px;
    display: flex;
    align-items: center;
}

.home-section .home-content .bx-menu,
.home-section .home-content .text{
    color: var(--primary-blue);
    font-size: 35px;
}

.home-section .home-content .bx-menu{
    margin: 0 15px;
    cursor: pointer;
}

.home-section .home-content .text{
    font-size: 26px;
    font-weight: 600;
}


@media screen and (max-width: 900px) {
    .home-section{
        width: 100%
    }
}


.profile_name_v{
    font-size: 0.9rem;
}

.job_v{
    font-size: 0.6rem;
}

.logout_icon{
    font-size: 1.8rem;
    cursor: pointer;
}

.card-header{
    background: var(--default-gray) !important;
    color: white;
}

.form-control-text{
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    padding: 0.5em;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
}

.form-control-select{
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    padding: 0.5em;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
}


.btn{
    border-radius: 50px !important;
    margin-left: 5px !important;
    min-width: 100px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.btn:hover{
    background: #efefef !important;
}

.btn-primary{
    background: white !important;
    border: 2px solid  var(--primary-blue) !important;
    color: var(--primary-blue) !important;
}

.btn-info, .btn-default{
    background: white !important;
    border: 2px solid  var(--default-gray) !important;
    color: var(--default-gray) !important;
}

.btn-success{
    background: white !important;
    border: 2px solid var(--secondary-orange);
    color: var(--secondary-orange) !important;
}

input[type=text].error{
    border: 1px solid #ff0000;
}

input[type=text]:read-only{
    background-color: #efefef;
}

select.error{
    border: 1px solid #ff0000;
}

textarea.error{
    border: 1px solid #ff0000;
}

.text-indicator{
    font-size: 2em;
    font-weight: bolder;
}

.arrow-back{
    font-size: 1.8rem;
    cursor: pointer;
}

.celda_activa{
    font-style: normal;
}

.celda_inactiva{
    font-style: italic;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    
    min-height: 40PX;
    min-width: 80px;
    align-content: center;
}

.react-datepicker-popper{
    z-index: 2 !important;
}
iframe {
    position: relative;
}

iframe::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1; /* Esto asegura que el contenido del iframe no sea clicable */
}


.porcentaje-auditoria{
    font-weight: 400;
    font-size: 0.8rem;
    margin-left: 5px;
}
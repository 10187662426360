:root{
  font-size: 100%;
  font-size: 16px;
  line-height: 1.5;
  --primary-blue: #464f9b;
  --primary-blue-oscure: #3f478d;
  --secondary-orange: #eb7d4c;
  --info-yellow: #dee198;
  --default-gray: #808080;
}

body{
  padding: 0;
  margin: 0;
  font-family: 'Monserrat', sans-serif;
  font-weight: 500;
}

h1{
  font-size: 2.25rem;
  font-weight: 700;
}

h2{
  font-size: 1.5rem;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: var(--primary-blue);
}

a:hover{
  text-decoration: underline;
}

.small{
  font-size: 80%;
  text-align: center;
}

.split-screen{
  display: flex;
  flex-direction: column;
}

.left{
  height: 200px;
}

.left, .right{
  display: flex;
  justify-content: center;
  align-items: center;
}

.left{
  background: linear-gradient(rgba(69, 79, 155, 0.36), rgba(69, 79, 155, 0.36)), url('../../../public/images/fondo_login.jpg');
  background-size: cover;
}

.left .copy{
  color: white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -webkit-moz-osx-smoothing: grayscale;
}

.left p{
  font-weight: 400;

}

.right .copy{
  color: black;
  text-align: center;
} 

.right .copy p{
  margin: 1.5em 0;
  font-size: 0.875rem;
} 

.right form{
  width: 328px;
}

form input[type="text"],
form input[type="email"],
form input[type="password"] {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  padding: 1em;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
}

label{
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}


.input-container.password{
  position: relative;
} 


.input-container.password i{
  position: absolute;
  top: 42px;
  right: 16px;
} 

.checkbox-container input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;  
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  user-select: none;
  margin: 0 0 1.25rem 0;
}

.checkbox-container input:checked~.checkmark{
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
}

.checkmark{
  position: absolute;
  width: 24px;
  height: 24px;
  background: white;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  top: -4px;
  left: 0;
}

.checkmark:after{
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked~.checkmark:after{
  display: block;
}

.checkbox-container .checkmark:after{
  border: solid white;
  border-width: 0 3px 3px 0;
  width: 5px;
  height: 10px;
  left: 8px;
  top: 4px;
  transform: rotate(45deg);
}


.signup-btn{
  display: block;
  width: 100%;
  background: var(--primary-blue);
  color: white;
  font-weight: 600;
  border: none;
  padding: 1rem;
  border-radius: 50px;
  font-size: 1rem;
  /*text-transform: uppercase;*/
  letter-spacing: 0.5px;
}


.signup-btn:hover{
  background: #2c4893;
  cursor: pointer;
}

@media screen and (min-width: 900px){
  .split-screen{
      flex-direction: row;
      height: 100vh;
  }

  .left{
      display: flex;
      width: 60%;
      height: auto;
  }
  .right{
      display: flex;
      width: 40%;
      height: auto;
  }
}













